import {differenceInDays, format, parseISO, startOfDay} from 'date-fns';
import {compareISODates} from '@/utils/dates/compareISODates';

/**
 *
    active: boolean
    admission_plan: number // в днях
    code: string
    end_date: string // "2024-04-02T00:00:00.000000Z"
    group_size: number
    id: number
    program_id: number
    start_date: string //"2023-03-01T00:00:00.000000Z"
 */
const undef_date = 'Дата не определена';
const getClosestProgramSchedule = (schedules = []) => {
  if (!schedules.length) return false;
  let arSchedules =
    schedules.sort(compareISODates); /* сортировка от меньшего к большему */

  const scheduleWithGreatestDate =
    schedules.at(-1); /* последний элемент массива */
  const today = startOfDay(new Date());

  if (!scheduleWithGreatestDate) return false;
  const greatestDate = parseISO(scheduleWithGreatestDate.date_start);
  const isBeforeToday =
    differenceInDays(greatestDate, today, {addSuffix: true}) < 0;

  if (isBeforeToday) {
    return scheduleWithGreatestDate; /* нет дат больше текущей и массив отсортирован от меньшей к большей */
  }

  for (let schedule of arSchedules) {
    if (
      differenceInDays(parseISO(schedule.date_start), today, {
        addSuffix: true
      }) >= 0
    ) {
      return schedule; /* при первом элементе останавливаем цикл и возвращаем расписание */
    }
  }

  return false;
};

const getProgramDurationInWords = (days = -Infinity) => {
  switch (true) {
    case days % 7 === 0:
      return `${days % 7} недель`;
    case days > 0:
      return `${days} дней`;
    default:
      return '';
  }
};

export const prepareProgramCardHistoryView = item => {
  const {program} = item;
  const schedule = getClosestProgramSchedule(program.schedule);
  const pcStart = schedule?.start_date
    ? format(parseISO(schedule.start_date), 'dd.MM.yyyy')
    : undef_date;
  const pcDuration = getProgramDurationInWords(schedule?.admission_plan);

  return {
    pcId: item.program_id,
    pcActive: !!program?.active,
    pcDirect: program?.full_name ?? '',
    pcCaption: program?.department?.name ?? '',
    pcStart,
    pcDuration,
    pcPrice: 10000, // TODO: брать из запроса
    pcOldPrice: 20000, // TODO: брать из запроса
    pcType: program?.program_type?.name ?? '',
    pcFavorites: !!program?.favorite,
    pcComparison: !!program?.compare,
    pcDetailUrl: item?.detail_url,
    pcChildrenCount: item?.children_count !== null ? item.children_count : null
  };
};

export const prepareProgramCardSelectedView = item => {
  const schedule = getClosestProgramSchedule(item.schedule);
  const pcStart = schedule?.start_date
    ? format(parseISO(schedule.start_date), 'dd.MM.yyyy')
    : undef_date;
  const pcDuration = getProgramDurationInWords(schedule?.admission_plan);

  return {
    pcId: item.pivot.program_id,
    pcActive: !!item.active,
    pcDirect: item?.full_name ?? '',
    pcCaption: item?.department?.name ?? '',
    pcStart,
    pcDuration,
    pcPrice: 10000, // TODO: поле отсутствует в api
    pcOldPrice: 20000, // TODO: поле отсутствует в api
    pcType: item?.program_type?.name ?? '',
    pcFavorites: true,
    pcComparison: !!item?.compare,
    pcDetailUrl: item?.detail_url,
    pcChildrenCount: item?.children_count !== null ? item.children_count : null
  };
};

/**
 * @param {Program} item
 */
export const prepareProgramCardRecommendedView = item => {
  const schedule = getClosestProgramSchedule(item.schedule);
  const pcStart = schedule?.date_start
    ? format(parseISO(schedule.date_start), 'dd.MM.yyyy')
    : undef_date;

  let price = 0;
  let oldPrice = 0;
  if (item?.price_detail?.discount_percent) {
    price = item?.price_detail?.discounted_price ?? 0;
    oldPrice = item?.price_detail?.price ?? 0;
  } else {
    price = item?.price_detail?.price ?? 0;
    oldPrice = 0;
  }

  return {
    pcId: item.id,
    pcActive: !!item.active,
    pcDirect: item?.department ?? '',
    pcCaption: item?.title ?? '',
    pcStart: pcStart,
    pcDuration: item?.duration ?? '',
    pcPrice: price,
    pcOldPrice: oldPrice,
    pcType: item?.program_type ?? '',
    pcFavorites: !!item?.favorite,
    pcComparison: !!item?.compare,
    pcDetailUrl: item?.detail_url,
    pcCampus: item?.campus ? item.campus : '',
    eduFormat: item?.edu_format ?? '',
    pcDurationValue: item?.duration_value ?? '',
    pcTrainingAreas: item?.training_areas ?? [],
    pcChildrenCount: item?.children_count !== null ? item.children_count : null
  };
};
