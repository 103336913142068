import Axios from '@/utils/Elk-Axios';
import {GET_SERVICE_MANAGER, GET_SERVICES, GET_CORP_SERVICES} from '@/api';
import {REFERENCEPAGE} from '@/router/routes';
import {
  SET_GROUPS,
  SET_LOADING,
  SET_INIT_STATE,
  SET_CORP_INIT_STATE,
  SET_USER_DATA,
  SET_PROGRAMS,
  SET_SERVICE_MANAGER,
  SET_CORP_GROUPS,
  SET_CORP_USER,
  SET_CORP_PROGRAMS
} from './templates';

export default {
  responseToGroups(_, response) {
    const getLinkService = value => {
      if (response?.type === 'corp') {
        return value.form ? `/dpo/corp/get-help/${value.id}` : value.url;
      }
      return value.form ? `${REFERENCEPAGE}/${value.id}` : value.url;
    };
    const mapService = value => {
      return {
        id: value?.id?.toString() ?? 0,
        code: value?.code ?? false,
        name: value?.name ?? '',
        fullName: value?.description ?? '',
        img: value?.icon || value?.image || '',
        link: getLinkService(value),
        disabled: value?.disabled ?? false,
        notification: value?.notification ?? '',
        favorite: false
      };
    };

    if (response?.data?.length) {
      const result = [];
      for (const item of response.data) {
        result.push({
          title: item.name,
          items: item.items.map(mapService)
        });
      }
      return result;
    }
    return [];
  },
  responseToPrograms(_, response) {
    const mapPrograms = value => {
      return {
        id: value?.id?.toString() ?? 0,
        name: value?.name ?? '',
        fullName: value?.full_name ?? ''
      };
    };

    if (Array.isArray(response.programs)) {
      return response.programs.map(mapPrograms);
    }
    return [];
  },
  responseToUserData(_, response) {
    const mapUser = value => {
      return {
        firstName: value?.first_name ?? '',
        lastName: value?.last_name ?? '',
        patronymic: value?.patronymic ?? '',
        email: value?.email ?? '',
        phone: value?.phone ?? ''
      };
    };

    return mapUser(
      Array.isArray(response.user) ? response.user[0] : response.user
    );
  },
  async fetchData(_, payload) {
    return (
      await Axios.get(
        payload.type === 'corp' ? GET_CORP_SERVICES : GET_SERVICES
      )
    )?.data?.data;
  },
  async fetchServiceManager({commit}) {
    try {
      const response = await Axios.get(GET_SERVICE_MANAGER);

      commit(SET_SERVICE_MANAGER, response?.data?.data?.service ?? []);
    } catch {
      // не удалось загрузить сервис
    }
  },
  async refreshData({dispatch, commit}, payload) {
    const responseData = await dispatch('fetchData', payload);
    const groups = await dispatch('responseToGroups', {
      data: responseData,
      type: payload?.type
    });
    const user = await dispatch('responseToUserData', responseData);
    const programs = await dispatch('responseToPrograms', responseData);

    if (payload.type === 'corp') {
      commit(SET_CORP_GROUPS, groups);
      commit(SET_CORP_USER, user);
      commit(SET_CORP_PROGRAMS, programs);
    } else {
      commit(SET_GROUPS, groups);
      commit(SET_USER_DATA, user);
      commit(SET_PROGRAMS, programs);
    }
  },
  async init({dispatch, commit, state}, payload) {
    if (payload?.type === 'corp' && state.isCorpInit) {
      return;
    } else if (payload?.type !== 'corp' && state.isInit) {
      return;
    }
    if (payload?.type === 'corp') {
      commit(SET_CORP_INIT_STATE, true);
    } else {
      commit(SET_INIT_STATE, true);
    }
    try {
      commit(SET_LOADING, true);
      await dispatch('refreshData', payload);
    } catch {
      commit(SET_GROUPS, []);
    } finally {
      commit(SET_LOADING, false);
    }
  }
};
