export default {
  getGroups: state => isCorp => {
    return isCorp ? state.corpGroups : state.groups ?? [];
  },
  countServices: state => isCorp => {
    let count = 0;
    if (isCorp) {
      state.corpGroups.forEach(e => {
        count += e.items.length;
      });
    } else {
      state.groups.forEach(e => {
        count += e.items.length;
      });
    }
    return count;
  },
  getProgram: state => id => {
    return state.programs.find(e => e.id === id);
  },
  getService: state => id => {
    for (let i = 0; i < state.groups.length; i += 1) {
      const item = state.groups[i].items.find(e => e.id === id);
      if (item) {
        return item;
      }
    }
    return null;
  },
  getServiceIdByCode: state => code => {
    for (let i = 0; i < state.groups.length; i += 1) {
      const item = state.groups[i].items.find(e => e.code === code);
      if (item) {
        return item.id;
      }
    }
    return null;
  },
  getServiceManager: state => () => {
    return state.serviceManager;
  },
  isEmpty(state) {
    return state.groups.length === 0;
  },
  getPrograms(state) {
    return state.programs ?? [];
  },
  getUserFullName(state) {
    let result = '';

    if (state.user.lastName) {
      result += `${state.user.lastName} `;
    }
    if (state.user.firstName) {
      result += `${state.user.firstName} `;
    }
    if (state.user.patronymic) {
      result += `${state.user.patronymic} `;
    }
    return result;
  }
};
