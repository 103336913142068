<template>
  <div v-if="arProgramm.length">
    <swiper
      v-bind="swiperOptions"
      class="program-sl"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="program in arProgramm"
        :key="program.pcId"
      >
        <program-card
          :pc-id="program.pcId"
          :pc-active="program.pcActive"
          :pc-direct="program.pcDirect"
          :pc-caption="program.pcCaption"
          :pc-start="program.pcStart"
          :pc-duration="program.pcDuration"
          :pc-price="program.pcPrice"
          :pc-old-price="program.pcOldPrice"
          :pc-type="program.pcType"
          :pc-favorites="program.pcFavorites"
          :pc-comparison="program.pcComparison"
          :pc-campus="program.pcCampus"
          :edu-format="program.eduFormat"
          :pc-duration-value="program.pcDurationValue"
          :pc-training-areas="program.pcTrainingAreas"
          :pc-detail-url="program.pcDetailUrl"
          :pc-children-count="program.pcChildrenCount"
          @returnFavoriteId="tossFavoriteId"
          @returnComparisonId="tossComparisonId"
        />
      </swiper-slide>

      <div class="program-sl__control-box">
        <div class="program-sl__navigation">
          <div class="swiper-button-prev program-sl__prev" />
          <div class="swiper-button-next program-sl__next" />
        </div>
        <div class="program-sl__pagination" />

        <a
          v-if="moreLink"
          :href="moreLink"
          class="more-link"
        >
          {{ moreLinkCaption }}
        </a>
      </div>
    </swiper>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {Navigation, Pagination, EffectFade, Autoplay} from 'swiper';
import {SwiperCore, Swiper, SwiperSlide} from 'swiper-vue2';
import ProgramCard from '@/components/programs/Program-card.vue';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

export default {
  name: 'ProgramSlider',

  components: {
    Swiper,
    SwiperSlide,
    'program-card': ProgramCard
  },

  props: {
    moreLink: String,
    moreLinkCaption: String,
    loop: {
      type: Boolean,
      default: true
    },
    arProgramm: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      name: 'ProgramSlider',
      swiperElem: null,
      swiperOptions: {
        grabCursor: true,
        slidesPerView: 4,
        spaceBetween: 24,
        perspective: false,
        loop: this.loop,
        speed: 400,
        paginationClickable: true,
        mousewheelControl: true,
        performanceMode: false,
        // autoplay: {
        //   // delay: 5000,
        //   delay: 1000,
        //   disableOnInteraction: false,
        //   pauseOnMouseEnter: true,
        // },
        navigation: {
          nextEl: '.program-sl__next',
          prevEl: '.program-sl__prev'
        },
        pagination: {
          type: 'fraction',
          el: '.program-sl__pagination',
          formatFractionTotal: this.numberFormatter,
          formatFractionCurrent: this.numberFormatter
        },

        breakpoints: {
          300: {
            slidesPerView: 2,
            loopedSlides: 2,
            spaceBetween: 10
          },
          680: {
            slidesPerView: 3,
            spaceBetween: 10,
            loopedSlides: 2
          },
          1000: {
            slidesPerView: 3,
            spaceBetween: 10,
            loopedSlides: 3
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 24,
            loopedSlides: 3
          },
          1500: {
            slidesPerView: 4,
            loopedSlides: 4,
            spaceBetween: 24
          }
        }
      }
    };
  },

  computed: {
    ...mapState(['offeredPrograms'])
  },
  watch: {
    arProgramm() {
      if (this.swiperElem) {
        this.$nextTick(() => {
          this.swiperElem.update();
        });
      }
    }
  },
  methods: {
    ...mapActions([
      'updateFavoriteOfferedProgramById',
      'updateComparisonOfferedProgramById'
    ]),
    numberFormatter(number) {
      return number > 9 ? number : '0' + number;
    },

    onSwiper(swiper) {
      this.swiperElem = swiper;
    },
    onSlideChange() {
      //
    },
    tossFavoriteId(id, isFavorite) {
      /**
       * меняем состояние в общем списке и если ок создаем emit
       */
      const result = this.updateFavoriteOfferedProgramById(id);
      if (result) {
        this.$emit('tossFavoriteId', id, isFavorite);
      }
    },
    tossComparisonId(id, inComparison) {
      /**
       * меняем состояние в общем списке и если ок создаем emit
       */
      const result = this.updateComparisonOfferedProgramById(id);
      if (result) {
        this.$emit('tossComparisonId', id, inComparison);
      }
    }
  }
};
</script>

<style lang="scss">
.program-sl {
  padding-bottom: 20px;

  .swiper-slide {
    height: auto;
  }

  .programm {
    height: 100%;
    max-width: 100%;
  }

  .program-sl__control-box {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 33px;
    padding: 0 5px;
    width: 100%;
    z-index: 10;

    .program-sl__pagination {
      color: #6b7a99;
      font-weight: 400;
      margin-right: 10px;
    }

    .program-sl__navigation {
      display: flex;
      justify-content: space-between;
      flex-shrink: 0;
      width: 86px;
      margin-right: 32px;

      .swiper-button-prev,
      .swiper-button-next {
        position: relative;
        width: 24px;
        height: 24px;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        margin-top: 0;

        &::after,
        &::after {
          font-size: 11px;
          color: var(--font-color);
          font-weight: bold;
          transition: var(--great-transition);
          transform: scale(1);
        }
      }
    }

    .more-link {
      font-weight: 600;
      margin-left: auto;
      flex-shrink: 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .program-sl {
    width: calc(100% + 200px);

    .programm {
      // max-width: 376px;
      min-width: 292px;
    }

    .program-sl__control-box {
      padding-right: 220px;
    }
  }
}

@media screen and (max-width: 800px) {
  .program-sl {
    .program-sl__control-box {
      .program-sl__navigation,
      .program-sl__pagination {
        display: none;
      }

      .more-link {
        margin-left: 0;
        margin-right: 0;
        flex-shrink: 1;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .program-sl {
    .programm {
      // max-width: 376px;
      min-width: 255px;
    }
  }
}
@media screen and (max-width: 360px) {
  .program-sl {
    .programm {
      // max-width: 376px;
      min-width: 240px;
    }
  }
}
</style>
