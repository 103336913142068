import Axios from '@/utils/Elk-Axios';
import {SET_SCHEDULE, SET_INIT_STATE, SET_LOADING} from "./templates"
import {GET_SCHEDULE} from '@/api';
import fakeData from './fakeData';

export default {
  namespaced: true,
  state: {
    schedule: [],
    isInit: false,
    isLoading: false,
  },
  mutations: {
    [SET_SCHEDULE](state, params) {
      state.schedule = params
    },
    [SET_INIT_STATE](state, params) {
      state.isInit = params
    },
    [SET_LOADING](state, params) {
      state.isLoading = params ?? false
    }
  },
  actions: {
    responseToSchedule(_, response) {
      const mapEvent = (value) => {
        // eslint-disable-next-line no-unused-vars
        const [_, timeStart] = value.date_start?.split(/\s/) ?? []
        // eslint-disable-next-line no-unused-vars
        const [__, timeEnd] = value.date_end?.split(/\s/) ?? []

        return {
          id: value.id ?? 0,
          type: value.form ?? '',
          typeOfEvent: value.type ?? '',
          eventBegin: timeStart ?? '',
          eventEnd: timeEnd ?? '',
          caption: value.name ?? '',
          direction: value.direction ?? '',
          venue: value.location ?? '',
          venueLink: value.link ?? '',
          auditorium: value.room ?? '',
          auditoriumLink: value.auditoriumLink ?? '',
          teacherRang: value.teacherRang ?? '',
          teacherName: value.speaker ?? '',
        }
      }

      const mapSchedule = (value) => {
        return {
          date: value.date ?? '',
          events: Array.isArray(value.events) ? value.events.map(mapEvent) : [],
          freeEvents: value.freeEvents || []
        }
      }

      const result = []

      if (Array.isArray(response)) {
        response.forEach((e) => {
          if (e.date) {
            result.push(mapSchedule(e))
          }
        })
      }

      return result
    },
    async fetchSchedule() {
      return (await Axios.get(GET_SCHEDULE))?.data
    },
    async refreshData({dispatch, commit}) {
      const response = await dispatch('fetchSchedule')
      const responseData = await dispatch('responseToSchedule', response?.data)
      commit(SET_SCHEDULE, responseData)
    },
    async init({dispatch, commit, state}) {

      if (state.isInit) {
        return
      }
      commit(SET_INIT_STATE, true)
      commit(SET_LOADING, true)
      try {
        await dispatch('refreshData')
      } catch (e) {
        //
      } finally {
        commit(SET_LOADING, false)
      }
    }
  },
}
