<template>
  <ErrorPagePublic v-if="isPublicUser"/>
  <div v-else :class="{ body: true, active: menuActive }">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <loading v-if="isLoadingSchedule || isLoadUser" />
        <section
          v-else
          class="notifications-section"
        >
          <div class="container">
            <h1 class="page-title">
              {{ name }}
            </h1>
            <schedule-of-events />
          </div>
        </section>
      </main>
    </div>
    <page-footer />
  </div>
</template>
<script>
// import recoveryStart from "@/components/authComponent/recoveryStart.vue"
import menuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import Loading from '@/components/common/elkLoading';

import ScheduleOfEvents from '@/components/schedule/ScheduleOfEvents.vue'; //календарь с расписанием
import ElkErrorPagePublic from '../404/ElkErrorPagePublic.vue';
import {mapState, mapGetters} from 'vuex';
import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import EmptyWrapper from '@/components/common/elkEmptyWrapper';
import ErrorPagePublic from '@/views/404/index.vue';

export default {
  components: {
    ErrorPagePublic,
    // recoveryStart,
    Loading,
    menuWrapper,
    PageFooter,
    ScheduleOfEvents,
    ElkErrorPagePublic,
    EmptyWrapper,
    EmptyDataMessage
  },
  data() {
    return {
      name: 'Моё расписание',
      pageClass: 'my-schedule-page'
    };
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser', 'isAuth', 'isLoadUser']),
    isLoadingSchedule() {
      return this.$store.state.schedule.isLoading ?? [];
    }
  },
  beforeMount() {
    this.$store.dispatch('schedule/init');
  }
};
</script>
<style lang="scss">
/* основные стили написаны в фале vi-calendar, который находится по пути style/components/vi-calendar.scss */
</style>
