<template>
  <div
    id="wrapper-form-order"
    class="wrapper-form-order"
  >
    <el-form ref="form">
      <h1>
        {{ localeText.title }}
      </h1>
      <div class="description-order">{{ localeText.orderNameProgram }}:</div>
      <p class="subtitle-order">
        {{ cartItem.title }}
      </p>

      <el-form-item class="content-table__order">
        <div class="table__order-start">
          <div
            v-if="cartItem.childrenCount"
            class="tag__cell order-start__tag"
          >
            <div class="order__cell-name">
              {{ localeText.orderSpecialization }}
            </div>
            <div class="tag__wrapper">
              <el-tag
                v-for="specialization in specializations"
                :key="specialization.id"
                class="order-tag__mod"
                :class="{start__mod: specialization.id === form.program_id}"
                type="info"
                @click="onSelectSpecialization(specialization.id)"
              >
                {{ specialization.title }}
              </el-tag>
            </div>
            <!-- hidden desktop -->
            <div class="tag__wrapper-mobile">
              <el-select
                v-model="form.program_id"
                placeholder="Выберите из списка"
                class="select-mobile__order-tag"
                @change="onSelectSpecialization"
              >
                <el-option
                  v-for="specialization in specializations"
                  :key="specialization.id"
                  :label="specialization.title"
                  :value="specialization.id"
                />
              </el-select>
            </div>
            <!-- hidden desktop -->
          </div>

          <template v-if="!cartItem.childrenCount || form.program_id">
            <div
              v-if="SignFinLiteracy"
              class="tag__cell order-start__tag"
            >
              <div class="order__cell-name">
                {{ localeText.orderStart }}
              </div>
              <div class="tag__wrapper">
                <el-tag
                  v-for="item in finLiteracyRegionSchedule"
                  :key="item.id"
                  class="order-tag__mod"
                  :class="{start__mod: item.id === form.schedule_id}"
                  type="info"
                  @click="onSelectSchedule(item.id)"
                >
                  {{ item.dateStartStr }}
                </el-tag>
              </div>
              <!-- hidden desktop -->
              <div class="tag__wrapper-mobile">
                <el-tag
                  v-for="item in finLiteracyRegionSchedule"
                  :key="item.id"
                  class="order-tag__mod"
                  :class="{start__mod: item.id === form.schedule_id}"
                  type="info"
                  @click="onSelectSchedule(item.id)"
                >
                  {{ item.dateStartStr }}
                </el-tag>
              </div>
              <!-- hidden desktop -->
            </div>

            <div
              v-else
              class="tag__cell order-start__tag"
            >
              <div class="order__cell-name">
                {{ localeText.orderStart }}
              </div>
              <div class="tag__wrapper">
                <el-tag
                  v-for="item in scheduleOptions"
                  :key="item.value"
                  class="order-tag__mod"
                  :class="{start__mod: item.value === form.schedule_id}"
                  type="info"
                  @click="onSelectSchedule(item.value)"
                >
                  {{ item.label }}
                </el-tag>
              </div>
              <!-- hidden desktop -->
              <div class="tag__wrapper-mobile">
                <el-select
                  v-model="form.schedule_id"
                  placeholder="Дата"
                  class="select-mobile__order-tag"
                  @change="onSelectSchedule"
                >
                  <el-option
                    v-for="item in scheduleOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <!-- hidden desktop -->
            </div>
          </template>

          <!--          <div class="tag__cell">-->
          <!--            <div class="order__cell-name">Продолжительность</div>-->
          <!--            <div class="order__cell-info">-->
          <!--              {{ cartItem.duration }}-->
          <!--            </div>-->
          <!--          </div>-->
          <div
            v-if="cartItem"
            class="tag__cell"
          >
            <div class="order__cell-name">
              {{ localeText.issuedDocument }}
            </div>
            <div class="order__cell-info">
              {{ cartItem.documentType }}
            </div>
          </div>
          <div class="tag__cell">
            <div class="order__cell-name">
              {{ localeText.cost }}
              <el-popover
                v-if="cartItem.discountedReason"
                placement="top-start"
                :width="240"
                trigger="hover"
                class="order__cell-popover"
                :content="cartItem.discountedReason"
              >
                <template #reference>
                  <i class="el-icon-info" />
                </template>
              </el-popover>
            </div>
            <div class="order__cell-info">
              <div class="cell__price">
                <template v-if="cartItem?.price?.start || cartItem?.price?.end">
                  <span
                    v-if="cartItem.price?.start"
                    class="discount"
                  >
                    {{ cartItem.price?.start }} ₽
                  </span>
                  <span
                    v-if="cartItem?.price?.end"
                    class="all-price"
                  >
                    {{ cartItem.price?.end || 0 }} ₽
                  </span>
                </template>
                <span
                  v-if="!cartItem?.price?.end"
                  class="free"
                >
                  {{ localeText.free }}!
                </span>
              </div>
            </div>
          </div>
          <!-- <div class="tag__cell cell__discount">
            <div class="order__cell-name">
              Скидка по промокоду
            </div>
            <div class="order__cell-info">
              — 2 500 ₽
            </div>
          </div> -->
          <div class="tag__cell">
            <div class="order__cell-name">
              {{ localeText.totalCost }}
            </div>
            <div class="order__cell-info">{{ totalPrice }} ₽</div>
          </div>
          <!-- <div class="tag__cell cell__promo">
            <div class="order__cell-name">
              У вас есть промокод?
            </div>
            <div class="order__cell-info">
              <el-input
                v-model="input"
                placeholder="HSE2022"
                clearable
              />
              <el-button
                type="primary"
                class="btn__promo"
                plain
                @click="open"
              >
                Применить промокод
              </el-button>
            </div>
          </div> -->
        </div>
      </el-form-item>
      <el-form-item class="flex justify-end">
        <div v-if="cartItem.nds_rate === null">
          {{ localeText.noNds }}
        </div>
        <div v-else>
          {{ formatNdsRate(cartItem.nds_rate) }}
        </div>
      </el-form-item>

      <el-form-item class="container-user__data">
        <div class="user__data-title">{{ localeText.orderYourData }}</div>
        <template v-if="getFullName">
          <div class="data__title-name">
            {{ localeText.orderFullName }}
          </div>
          <div class="data__full-name">
            {{ getFullName }}
          </div>
        </template>

        <div class="wrapper-data__contact-info">
          <div
            v-if="phone"
            class="contact__tel"
          >
            <span>
              {{ localeText.phoneNumber }}
            </span>
            <p>{{ phone }}</p>
          </div>
          <div
            v-if="email"
            class="contact__mail"
          >
            <span>Email</span>
            <p>{{ email }}</p>
          </div>
        </div>

        <template v-if="SignFinLiteracy">
          <fin-literacy
            ref="literacy"
            :localization="localization"
          />
        </template>
      </el-form-item>

      <el-form-item>
        <div class="alert alert--attention">
          <p v-html="localeHtml.tooltipCheckInfo" />
        </div>
      </el-form-item>

      <el-form-item v-if="isForeigner">
        <div
          class="inputCheckbox"
          style="margin-bottom: unset"
        >
          <input
            id="is_foreigner_checkbox"
            v-model="form.invitation"
            class="custom-checkbox"
            type="checkbox"
            name="checkbox"
          />
          <label for="is_foreigner_checkbox">
            {{ localeText.needInvite }}
          </label>
        </div>
      </el-form-item>

      <el-form-item v-if="freeProgram.is_free_program && finLiteracy !== true">
        <p class="subtitle-order">
          {{ localeText.addDocuments }}
        </p>
        <div class="col-input-wrapper mt-5 w-full">
          <input-file
            id="freeProgram"
            :value="$v.freeProgram.files_free_program"
            :multiple="true"
            text-align="center"
            :required="true"
            :accept-extensions="freeProgram.arr_image_extensions"
            :show-alert-info="true"
            :label="localeText.attachDocuments"
            class="w-full"
          />
        </div>
      </el-form-item>
      <el-form-item
        v-if="contractTypes.length && !freeProgram.is_free_program"
        class="order__radio-buttons"
      >
        <contract-types
          v-model="form.contract_type"
          v-loading="isLoadContractData"
          :title="localeText.selectContractType"
          :contract-types="contractTypes"
          @change="onChangeContractType"
        />
      </el-form-item>

      <part-form-contract-individual
        v-show="
          form.contract_type === 'BITVUZ.ТрехстороннийСФизЛицом' &&
          isInitIndividualData
        "
        :localization="localization"
        :vuelidate-data="$v.individualDocForm"
        :payers="individualPayers"
        :countries="countries"
        :genders="genders"
        :doc-types="individualDocTypes"
        @changePayer="onChangePayer"
      />

      <part-form-contract-legal
        v-show="
          form.contract_type === 'BITVUZ.ТрехстороннийСЮрЛицом' &&
            isInitLegalData
        "
        :localization="localization"
        :payers="legalPayers"
        :vuelidate-data="$v.legalDocForm"
        :available-based="basisDocuments"
        @changePayer="onChangePayer"
      />

      <el-form-item class="order__checkbox">
        <div
          v-if="offerDocUrl && form.contract_type === 'BITVUZ.Оферты'"
          class="inputCheckbox"
        >
          <input
            id="checkbox2"
            v-model="form.offer_document"
            class="custom-checkbox"
            type="checkbox"
            name="checkbox2"
          />
          <label for="checkbox2">
            <span v-html="localeHtml.agreeOffer" />
          </label>
        </div>
        <div class="inputCheckbox">
          <input
            id="checkbox1"
            v-model="form.acquainted_docs"
            class="custom-checkbox"
            type="checkbox"
            name="checkbox1"
          />
          <label for="checkbox1">
            <span v-html="localeHtml.agreeDocHse" />
          </label>
        </div>
        <div class="inputCheckbox">
          <input
            id="checkbox"
            v-model="form.confirmation_data"
            class="custom-checkbox"
            type="checkbox"
            name="checkbox"
          />
          <label for="checkbox">
            {{ localeText.agreeReliability }}
          </label>
        </div>
      </el-form-item>

      <el-form-item
        v-if="!cartItem.free_program && cartItem.freeLearningReasons?.length"
      >
        <div class="user__data-title">
          {{ localeText.freeLearningReason }}
        </div>

        <el-select
          v-model="form.free_learning_reason_id"
          placeholder="Основание для бесплатного обучения"
          class="select-free_reason"
          @change="onSelectFreeLearningReason"
        >
          <el-option
            v-for="item in cartItem.freeLearningReasons"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="
          totalPrice &&
          filterPaymentOptions.length > 1 &&
          !form.free_learning_reason_id
        "
        class="order__radio-buttons"
      >
        <div class="user__data-title">
          {{ localeText.selectPayment }}
        </div>
        <el-radio-group v-model="form.payment_method">
          <el-radio
            v-for="opts in filterPaymentOptions"
            :key="opts.value"
            :label="opts.value"
          >
            {{ opts.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item class="el-order__button">
        <el-button
          v-loading.fullscreen.lock="fullscreenLoading"
          class="btn"
          type="primary"
          :disabled="
            (offerDocUrl === '' && form.contract_type === 'BITVUZ.Оферты') ||
            (form.contract_type === 'BITVUZ.Оферты' && !form.offer_document) ||
            !form.confirmation_data ||
            !form.acquainted_docs ||
            !finLiteracyVerify
          "
          @click="onSubmit"
        >
          {{ localeText.placeAnOrder }}
        </el-button>
        <button
          class="btn btn__el-form-back"
          type="button"
          @click="$router.back()"
        >
          {{ localeText.comeBack }}
        </button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {CONGRATULATIONONPAYMENT} from '@/router/routes';
import {mapGetters} from 'vuex';
import Axios from '@/utils/Elk-Axios';
import {
  GET_PAYMENTS,
  REFERENCES_CONTRACT_TYPES,
  REFERENCES_BASIS_DOCUMENTS,
  REFERENCES_GENDERS,
  REFERENCES_IDENTITY_DOC_TYPES,
  REFERENCES_COUNTRIES,
  GET_CONTRAGENT_TYPES,
  GET_PROGRAM_SPECIALIZATIONS
} from '@/api';
import FinLiteracy from '@/components/formOrder/finLiteracy.vue';
import {filterBus} from '@/main';
import ContractTypes from '@/components/formOrder/contractTypes';
import PartFormContractIndividual from '@/components/formOrder/partFormContractIndividual';
import PartFormContractLegal from '@/components/formOrder/partFormContractLegal';
import {validationMixin} from 'vuelidate';
import {required, requiredIf, email} from 'vuelidate/lib/validators';
import {format} from 'date-fns';
import {ORDER_CREATE} from '@/api';
import mapToPartForm from '@/components/formOrder/mapToPartForm';
import localization from '@/i18n/localization';
import setNotification from '@/utils/setNotification';
import finLiteracy from '@/components/formOrder/finLiteracy.vue';
import InputFile from '@/components/inputComponent/InputFile.vue';

export default {
  name: 'NewOrderForm',
  components: {
    InputFile,
    PartFormContractLegal,
    PartFormContractIndividual,
    ContractTypes,
    FinLiteracy
  },
  mixins: [validationMixin],
  props: {
    cartItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      localization: localization('dpo.order'),
      form: {
        payment_method: '',
        program_id: !this.cartItem.childrenCount ? this.cartItem.id : null,
        schedule_id: 0,
        promo_code: '',
        acquainted_docs: false,
        confirmation_data: false,
        invitation: false,
        offer_document: false,
        finForm: '',
        contract_type: '',
        free_learning_reason_id: ''
      },
      freeProgram: {
        is_free_program: this.cartItem.freeProgram,
        arr_image_extensions: ['jpg', 'jpeg', 'pdf', 'png'],
        files_free_program: []
      },
      legalDocForm: {
        payer_id: '',
        name: '',
        full_name: '',
        inn: '',
        kpp: '',
        ogrn: '',
        manager_name: '',
        signatory_name: '',
        signatory_position: '',
        based: '',
        place_registration: '',
        power_attorney_num: '',
        power_attorney_date: '',
        bic: '',
        bank_name: '',
        correspondent_account: '',
        checking_account: '',
        actual_address: '',
        legal_address: '',
        phone: '',
        email: '',
        contact_name: '',
        contact_position: '',
        contact_phone: '',
        contact_mail: ''
      },
      individualDocForm: {
        payer_id: '',
        full_name: '',
        date_birth: '',
        gender: '',
        place_birth: '',
        phone: '',
        actual_address: '',
        registration_address: '',
        email: '',
        type_document: '',
        serial_number: '',
        number: '',
        date_issue: '',
        issued_by: '',
        code: '',
        validity: '',
        date_registration: '',
        citizenship: '',
        file: ''
      },
      individualPayers: [],
      legalPayers: [],
      contractTypesTotal: [],
      individualDocTypes: [],
      genders: [],
      countries: [],
      basisDocuments: [],
      SignFinLiteracy: this.cartItem.finLiteracy ?? false,
      finLiteracyVerify: !this.cartItem.finLiteracy,
      needInvitation: false,
      paymentOptions: [],
      offerDocUrl: '',
      fullscreenLoading: false,
      isInitIndividualData: false,
      isInitLegalData: false,
      isLoadContractData: false,
      finLiteracyRegionSchedule: [
        {
          dateStartStr:
            localization('dpo.order').widgetCaption('orderNoSchedule'),
          id: 0
        }
      ],
      specializations: []
    };
  },
  computed: {
    finLiteracy() {
      return finLiteracy;
    },
    ...mapGetters([
      'getFullName',
      'email',
      'phone',
      'isForeigner',
      'getLinkUrlBySignature'
    ]),
    localeText() {
      return {
        title: this.localization.widgetCaption('orderTitle'),
        orderSpecialization: this.localization.widgetCaption(
          'orderSpecialization'
        ),
        orderNameProgram: this.localization.widgetCaption('orderNameProgram'),
        orderStart: this.localization.widgetCaption('orderStart'),
        issuedDocument: this.localization.widgetCaption('issuedDocument'),
        totalCost: this.localization.widgetCaption('totalCost'),
        NdsRate: this.localization.widgetCaption('ndsRate'),
        noNds: this.localization.widgetCaption('noNds'),
        orderYourData: this.localization.widgetCaption('orderYourData'),
        orderFullName: this.localization.widgetCaption('orderFullName'),
        needInvite: this.localization.widgetCaption('needInvite'),
        addDocuments: this.localization.widgetCaption('addDocuments'),
        attachDocuments: this.localization.widgetCaption('attachDocuments'),
        selectContractType:
          this.localization.widgetCaption('selectContractType'),
        agreeReliability: this.localization.widgetCaption('agreeReliability'),
        selectPayment: this.localization.widgetCaption('selectPayment'),
        placeAnOrder: this.localization.widgetCaption('placeAnOrder'),
        orderNoSchedule: this.localization.widgetCaption('orderNoSchedule'),
        comeBack: this.localization.commonCaption('comeBack'),
        cost: this.localization.commonCaption('cost'),
        free: this.localization.commonCaption('free'),
        errorFillFields: this.localization.commonCaption('errorFillFields'),
        phoneNumber: this.localization.commonCaption('phoneNumber'),
        freeLearningReason:
          this.localization.commonCaption('freeLearningReason')
      };
    },
    localeHtml() {
      return {
        tooltipCheckInfo: this.localization
          .widgetCaption('tooltipCheckInfo')
          .replace('{link}', this.getLinkUrlBySignature('elk.profile')),
        agreeOffer: this.localization
          .widgetCaption('agreeOffer')
          .replace('{link}', this.offerDocUrl),
        agreeDocHse: this.localization.widgetCaption('agreeDocHse')
      };
    },
    contractTypes() {
      return this.contractTypesTotal.filter(
        e => e.code !== 'BITVUZ.Оферты' || !!this.offerDocUrl
      );
    },
    isPaySberHse() {
      return (
        this.form.contract_type === 'BITVUZ.Оферты' &&
        !this.cartItem.price?.start
      );
    },
    filterPaymentOptions() {
      return this.paymentOptions.filter(e => {
        if (e.value === 'sberpay' || e.value === 'card') {
          return this.isPaySberHse;
        }
        return true;
      });
    },
    totalPrice() {
      return this.cartItem?.price?.end ?? 0;
    },
    scheduleOptions() {
      let schedule = [];

      if (this.cartItem) {
        if (this.cartItem.childrenCount) {
          schedule = this.specializations
            ?.find(item => item.id === this.form.program_id)
            ?.schedule?.map(e => ({
              value: e.id ?? '',
              label: e.date_start ?? '',
              offerUrl: e.offer_document,
              region: e.region
            }));
        } else {
          schedule = this.cartItem.schedule?.map(e => ({
            value: e.id ?? '',
            label: e.dateStartStr ?? '',
            offerUrl: e.offerUrl
          }));
        }
      }

      return schedule ?? [];
    }
  },
  watch: {
    filterPaymentOptions(v) {
      if (v.length >= 1) {
        this.form.payment_method = v[0].value;
      }
    }
  },
  mounted() {
    this.initPaymentsOptions();
    if (!this.SignFinLiteracy) {
      this.initDocTypes();
      this.initPayers();
    }

    if (this.cartItem.childrenCount) {
      this.fetchSpecializations();
    }

    this.init();

    filterBus.$on('finLiteracy', value => {
      this.finLiteracyVerify = true;
      this.form.finForm = value;
    });
    filterBus.$on('finLiteracyRegion', value => {
      let upd = false;
      let program = this.cartItem;

      if (this.cartItem.childrenCount) {
        program = this.specializations?.find(
          item => item.id === this.form.program_id
        );

        if (program) {
          program.schedule = program?.schedule.map(e => {
            return {
              id: e.id,
              dateStartStr: e.date_start,
              offerUrl: e.offer_document,
              region: e.region
            };
          });
        }
      }

      program?.schedule.forEach(el => {
        if (el.region === value) {
          if (!upd) this.finLiteracyRegionSchedule = [];
          this.finLiteracyRegionSchedule.push(el);
          this.form.schedule_id = el.id;
          upd = true;
        }
      });

      if (!upd) {
        this.finLiteracyRegionSchedule = [
          {dateStartStr: this.localeText.orderNoSchedule, id: 0}
        ];
        this.form.schedule_id = 0;
      }
    });
  },
  methods: {
    formatNdsRate(rate) {
      const ndsText = this.localeText.NdsRate;
      return ndsText.replace('{rate}', rate);
    },
    orderSuccess(url) {
      if (url) {
        window.location.replace(url);
      } else {
        this.$router.replace({
          path: CONGRATULATIONONPAYMENT
        });
      }
    },
    getPayload() {
      const payloadData = [];

      payloadData.push({
        ...this.form,
        auto_discount: this.cartItem.isAutoDiscount,
        auto_discount_reason: this.cartItem.discountedReason,
        contract_type_id:
          this.contractTypes.find(e => e.code === this.form.contract_type)
            ?.id ?? '',
        offer_document:
          this.form.contract_type === 'BITVUZ.Оферты'
            ? this.form.offer_document
            : false
      });

      if (this.form.contract_type === 'BITVUZ.ТрехстороннийСФизЛицом') {
        payloadData.push(this.individualDocForm);
      } else if (this.form.contract_type === 'BITVUZ.ТрехстороннийСЮрЛицом') {
        payloadData.push(this.legalDocForm);
      }
      if (this.freeProgram.is_free_program) {
        payloadData.push(this.freeProgram);
      }

      const result = new FormData();

      const appendData = appData => {
        for (const [key, value] of Object.entries(appData)) {
          if (key === 'files_free_program' && Array.isArray(value)) {
            value.forEach(file => result.append('files_free_program[]', file));
          } else if (Array.isArray(value) && value[0] instanceof File) {
            result.append(key, value[0]);
          } else if (key === 'finForm' && value) {
            for (const [finKey, finVal] of Object.entries(value)) {
              result.append(`${key}[${finKey}]`, finVal);
            }
          } else {
            // Приводит даты к одному виду
            if (value && (key.includes('date') || key.includes('validity'))) {
              result.append(key, format(new Date(value), 'dd-MM-yyyy'));
            } else {
              result.append(key, value);
            }
          }
        }
      };

      payloadData.forEach(appendData);

      return result;
    },
    onSelectSchedule(value) {
      if (this.form.schedule_id !== value) {
        this.form.schedule_id = value;
      }
      this.offerDocUrl =
        this.scheduleOptions.find(e => e.value === value)?.offerUrl ?? '';
      if (!this.offerDocUrl) {
        this.form.offer_document = false;
        if (this.form.contract_type === 'BITVUZ.Оферты') {
          this.form.contract_type = '';
        }
      }
    },
    onSelectFreeLearningReason(value) {
      if (value) {
        this.form.payment_method = '';
      }
    },
    onSelectSpecialization(id) {
      this.form.program_id = id;
      this.form.schedule_id = null;
    },
    async onSubmit() {
      if (!this.totalPrice) {
        this.form.payment_method = 'without_payment';
      }

      const validResult = this.validation();
      if (!validResult.valid) {
        setNotification({
          message: validResult.message || this.localeText.errorFillFields
        });
        return;
      }

      this.fullscreenLoading = true;
      let response;
      try {
        response = (await Axios.post(ORDER_CREATE, this.getPayload()))?.data;
        this.$store.dispatch('cart/refreshCartItems');

        this.orderSuccess(response?.data?.payment_url);
      } catch (e) {
        setNotification({message: e.response?.data?.message ?? ''});
      } finally {
        this.fullscreenLoading = false;
      }
    },
    async initPayers() {
      const data = await this.fetchPayers();
      data.forEach(e => {
        if (e.code === 'BITVUZ.ТрехстороннийСФизЛицом') {
          this.individualPayers = e.contragents?.length ? e.contragents : [];
        } else if (e.code === 'BITVUZ.ТрехстороннийСЮрЛицом') {
          this.legalPayers = e.contragents ?? [];
        }
      });
    },
    async fetchPayers() {
      let result = [];
      try {
        const response = (await Axios.get(GET_CONTRAGENT_TYPES))?.data;
        result = response.data ?? [];
      } catch {
        // Не удалось получить данные
      }
      return result;
    },
    async initPaymentsOptions() {
      this.isLoading = true;
      let response;

      try {
        response = (await Axios.get(GET_PAYMENTS))?.data;
      } catch (e) {
        setNotification({message: e.response?.data?.message ?? ''});
      }

      if (response) {
        this.paymentOptions = response.data;
      }

      if (this.paymentOptions.length) {
        this.form.payment_method = this.paymentOptions[0].value;
      }

      this.isLoading = false;
    },
    async initDocTypes() {
      try {
        const response = (await Axios.get(REFERENCES_CONTRACT_TYPES))?.data;
        this.contractTypesTotal = response?.data ?? [];
      } catch {
        // Не удалось получить типы договоров
      }
    },
    init() {
      const currentItem = this.scheduleOptions.length
        ? this.scheduleOptions[0]
        : null;
      this.form.schedule_id = currentItem?.value ?? 0;
      this.offerDocUrl = currentItem?.offerUrl ?? '';
    },
    validation() {
      this.$v.$touch();
      const result = {
        valid: true,
        message: ''
      };
      if (this.form.contract_type === 'BITVUZ.ТрехстороннийСЮрЛицом') {
        result.valid = !this.$v.legalDocForm.$error;
        result.message = '';
      } else if (this.form.contract_type === 'BITVUZ.ТрехстороннийСФизЛицом') {
        result.valid = !this.$v.individualDocForm.$error;
        result.message = '';
      } else if (
        this.form.payment_method === 'sberpay' ||
        this.form.payment_method === 'card'
      ) {
        result.valid = this.isPaySberHse;
        result.message = this.localeText.selectPayment;
      } else if (
        this.finLiteracy !== true &&
        this.freeProgram.is_free_program &&
        !this.freeProgram.files_free_program.length
      ) {
        result.valid = false;
        result.message = this.localeText.addDocuments;
      }
      if (
        result.valid &&
        this.contractTypes.length &&
        !this.form.contract_type &&
        !this.freeProgram.is_free_program
      ) {
        result.valid = false;
        result.message = this.localeText.selectContractType;
      }

      if (
        !this.cartItem.free_program &&
        this.cartItem.freeLearningReason?.length &&
        !this.form.free_learning_reason_id
      ) {
        result.valid = false;
        result.message = this.localeText.freeLearningReason;
      }

      return result;
    },

    async fetchLegalFormData() {
      if (this.isInitLegalData) {
        return;
      }
      try {
        const [responseBasisDocuments] = await Promise.allSettled([
          await Axios.get(REFERENCES_BASIS_DOCUMENTS)
        ]);
        if (responseBasisDocuments.status === 'fulfilled') {
          this.basisDocuments = responseBasisDocuments.value.data?.data ?? [];
        }
      } catch (e) {
        // Не удалось получить данные
      } finally {
        this.isInitLegalData = true;
      }
    },
    async fetchIndividualFormData() {
      if (this.isInitIndividualData) {
        return;
      }
      try {
        const [responseGenders, responseIndividualDocTypes, responseCountries] =
          await Promise.allSettled([
            Axios.get(REFERENCES_GENDERS),
            Axios.get(REFERENCES_IDENTITY_DOC_TYPES),
            Axios.get(REFERENCES_COUNTRIES)
          ]);
        if (responseGenders.status === 'fulfilled') {
          this.genders = responseGenders.value.data?.data ?? [];
        }
        if (responseIndividualDocTypes.status === 'fulfilled') {
          this.individualDocTypes =
            responseIndividualDocTypes.value.data?.data ?? [];
        }
        if (responseCountries.status === 'fulfilled') {
          this.countries = responseCountries.value.data?.data ?? [];
        }
      } catch {
        // Не удалось получить данные
      } finally {
        this.isInitIndividualData = true;
      }
    },
    async onChangeContractType() {
      this.isLoadContractData = true;
      if (this.form.contract_type === 'BITVUZ.ТрехстороннийСЮрЛицом') {
        await this.fetchLegalFormData();
      } else if (this.form.contract_type === 'BITVUZ.ТрехстороннийСФизЛицом') {
        await this.fetchIndividualFormData();
      }
      this.isLoadContractData = false;
    },
    async onChangePayer(payer) {
      if (this.form.contract_type === 'BITVUZ.ТрехстороннийСЮрЛицом') {
        this.legalDocForm.payer_id = payer ?? '';
        this.fillLegalFormByPayer(payer);
      } else if (this.form.contract_type === 'BITVUZ.ТрехстороннийСФизЛицом') {
        this.individualDocForm.payer_id = payer ?? '';
        this.fillIndividualFormByPayer(payer);
      }
    },
    fillIndividualFormByPayer(payerId) {
      if (payerId < 0) return;
      const tempI = this.individualPayers.findIndex(e => e.id === payerId);
      if (tempI >= 0) {
        const mapData = mapToPartForm(
          this.form.contract_type,
          this.individualPayers[tempI]
        );
        if (!mapData) return;
        for (const [key, value] of Object.entries(mapData)) {
          if (this.individualDocForm[key] !== null) {
            this.individualDocForm[key] = value;
          }
        }
      }
    },
    fillLegalFormByPayer(payerId) {
      if (payerId < 0) return;
      const tempI = this.legalPayers.findIndex(e => e.id === payerId);
      if (tempI >= 0) {
        const mapData = mapToPartForm(
          this.form.contract_type,
          this.legalPayers[tempI]
        );
        if (!mapData) return;
        for (const [key, value] of Object.entries(mapData)) {
          if (this.legalDocForm[key] !== null) {
            this.legalDocForm[key] = value;
          }
        }
      }
    },
    async fetchSpecializations() {
      Axios.get(GET_PROGRAM_SPECIALIZATIONS(this.cartItem.id)).then(
        ({data}) => (this.specializations = data.data)
      );
    }
  },
  validations: {
    legalDocForm: {
      name: {
        required
      },
      full_name: {
        required
      },
      inn: {
        required
      },
      kpp: {
        required
      },
      ogrn: {
        required
      },
      manager_name: {
        required
      },
      signatory_name: {
        required
      },
      signatory_position: {
        required
      },
      based: {
        required
      },
      place_registration: {
        ['required']: requiredIf(function (nestedModel) {
          return nestedModel.based === 'BITVUZ.Доверенности';
        })
      },
      power_attorney_num: {
        ['required']: requiredIf(function (nestedModel) {
          return nestedModel.based === 'BITVUZ.Доверенности';
        })
      },
      power_attorney_date: {
        ['required']: requiredIf(function (nestedModel) {
          return nestedModel.based === 'BITVUZ.Доверенности';
        })
      },
      bic: {
        required
      },
      bank_name: {
        required
      },
      correspondent_account: {
        required
      },
      checking_account: {
        required
      },
      actual_address: {
        required
      },
      legal_address: {
        required
      },
      phone: {
        required
      },
      email: {
        required,
        email
      },
      contact_name: {
        required
      },
      contact_position: {
        required
      },
      contact_phone: {
        required
      },
      contact_mail: {
        required,
        email
      }
    },
    freeProgram: {
      files_free_program: {
        required
      }
    },
    individualDocForm: {
      full_name: {
        required
      },
      date_birth: {
        required
      },
      gender: {
        required
      },
      place_birth: {
        required
      },
      phone: {
        required
      },
      actual_address: {
        required
      },
      registration_address: {
        required
      },
      email: {
        required,
        email
      },
      type_document: {
        required
      },
      serial_number: {},
      number: {
        required
      },
      date_issue: {
        required
      },
      issued_by: {
        required
      },
      code: {},
      validity: {
        required
      },
      date_registration: {},
      citizenship: {
        required
      },
      file: {
        required
      }
    }
  }
};
</script>

<style lang="scss">
.wrapper-form-order {
  display: flex;
  align-items: center;
  justify-content: center;

  .el-icon-info {
    color: $color-gray-dusk;
  }
  .el-select .el-input__inner {
    height: 40px;
  }
}
.el-form {
  width: 778px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
  border-radius: 4px;
  padding: 48px 101px;
  transition: width 0.2s ease-in-out;
  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 40px;
  }
  .description-order,
  .subtitle-order {
    color: $base-black-color;
  }
  .description-order {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .subtitle-order {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .tag__wrapper .el-tag {
    cursor: pointer;
  }
  .content-table__order {
    margin: 24px 0;
    .tag__wrapper-mobile {
      display: none;
      visibility: hidden;
      max-width: 164px;
      .el-select {
        .el-input__inner {
          height: 40px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--font-color);
          border: 1px solid #d3d8e6;
          &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--font-color);
          }
        }
      }
      .el-select__caret.el-input__icon:before {
        font-weight: bold;
        color: var(--font-color);
      }
    }
  }
  .tag__cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #edeff3;
    padding: 16px 0;
    .order__cell-name {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: var(--font-color);
    }
  }
  .cell__promo {
    flex-direction: column;
    align-items: baseline;
    .order__cell-info {
      width: 100%;
      margin-top: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-input.el-input--suffix,
      .el-button.btn__promo.el-button--primary.is-plain {
        width: calc(50% - 12px);
      }
      .el-input.el-input--suffix {
        input {
          height: 48px;
          border-color: #d3d8e6;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: var(--font-color);
          &:hover {
            border-color: var(--dark-blue);
          }
          &::placeholder {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: var(--font-color);
          }
        }
      }
      .el-button.btn__promo.el-button--primary.is-plain {
        height: 48px;
        background-color: #f0f5ff;
        border: 1px solid #8eb2ec;
        border-radius: 4px;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-blue);
        &:hover {
          background-color: var(--dark-blue);
          color: #fff;
          border-color: var(--dark-blue);
        }
      }
    }
  }
  .tag__cell.cell__discount {
    .order__cell-info {
      color: var(--dark-blue);
    }
  }

  .tag__cell:last-child {
    border-bottom: 1px solid #edeff3;
    padding-bottom: 24px;
  }
  .order-start__tag {
    .order-tag__mod.el-tag.el-tag--info.el-tag--light {
      padding: 8px 12px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #6b7a99;
      border: none;
      margin-left: 8px;
      background-color: #f8f9fb;
      border-radius: 4px;
    }
    .order-tag__mod.el-tag.el-tag--info.el-tag--light.start__mod {
      color: #0050cf;
    }
  }
  .discount {
    position: relative;
    overflow: hidden;
    width: fit-content;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6b7a99;
    margin-bottom: 5px;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #ffffff;
      border-top: 2px solid #6b7a99;
      transform: translateY(-50%) rotate(-9deg);
    }
  }
  .table__order-start {
    .order__cell-info {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--font-color);
      .cell__price {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .container-user__data {
    .data__title-name {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--font-color-alt);
      margin-bottom: 8px;
    }

    .wrapper-data__contact-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--font-color-alt);
        margin-bottom: 8px;
        display: inline-flex;
      }
      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: var(--font-color);
        margin-bottom: 0;
      }
      .contact__mail {
        p {
          color: var(--dark-blue);
        }
      }
    }
  }
  .container-user__data {
    margin-bottom: 24px;
  }
  .order__checkbox {
    margin-top: 24px;
    border-bottom: 1px solid #edeff3;
    .inputCheckbox:last-child {
      margin-bottom: 34px;
    }
  }
  .user__data-title,
  .data__full-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--font-color);
    margin-bottom: 24px;
  }

  .order__radio-buttons {
    margin: 24px 0 42px 0;
    .el-radio-group {
      display: flex;
      flex-direction: column;
      .el-radio__label {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: var(--font-color);
        padding-left: 8px;
      }
      .el-radio {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
        .el-radio__input.is-checked {
          .el-radio__inner {
            border-color: var(--dark-blue);
            background: var(--dark-blue);
          }
        }
        .el-radio__inner {
          width: 16px;
          height: 16px;
          border: 1px solid #6b7a99;
          top: 1px;
        }
      }
    }
  }
  .el-form-item {
    .el-form-item__content {
      line-height: unset;
    }
    .el-button.btn.el-button--primary,
    .btn.btn__el-form-back {
      height: 48px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
    .btn.btn__el-form-back {
      margin-top: 24px;
      background-color: #f0f5ff;
      color: var(--font-color-alt);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .alert.alert--attention {
      margin-bottom: 0;
      p {
        color: #002d6e;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        &:before {
          top: 2px;
          left: -36px;
        }
        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: var(--dark-blue);
        }
      }
    }
  }
  .el-form-item.el-order__button {
    margin-bottom: 0;
  }
  .el-form-item {
    .alert.alert--attention {
      background-color: #f0f5ff;
      border-radius: 8px;
      padding: 16px 16px 16px 56px;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #002d6e;
        margin-bottom: 0;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: calc(50% - 9px);
          left: -36px;
          display: block;
          width: 18px;
          height: 18px;
          background: url('../../assets/icons/icon-attention.svg') 100% 100%
            no-repeat;
        }
        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: var(--dark-blue);
        }
      }
    }
  }

  .alert.alert--attention {
    background-color: #f0f5ff;
    border-radius: 8px;
    padding: 16px 16px 16px 56px;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #002d6e;
      margin-bottom: 0;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: calc(50% - 9px);
        left: -36px;
        display: block;
        width: 18px;
        height: 18px;
        background: url('../../assets/icons/icon-attention.svg') 100% 100%
          no-repeat;
      }
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: var(--dark-blue);
      }
    }
  }
}
.el-popover.el-popper {
  word-break: normal;
  text-align: start;
}
.part-form-contract {
  .el-select {
    width: 100%;
  }
  .el-form-item__label {
    line-height: 16px;
    margin-bottom: 8px;
    text-align: left;
  }
  .el-autocomplete {
    width: 100%;
  }
  .input--mask {
    height: auto;
  }
  .el-form-item__error {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .is-error .el-input__inner,
  .is-error .el-input__inner:focus,
  .is-error .el-textarea__inner,
  .is-error .el-textarea__inner:focus,
  .is-error .input--mask,
  .is-error .input--mask:focus {
    border-color: #f56c6c;
  }
}

.select-free_reason {
  width: 100%;
}

// media

@media (max-width: 1440px) {
  .wrapper-form-order {
    .el-form {
      width: 641px;
      padding: 30px 49px;
    }
  }
}

@media (max-width: 1024px) {
  .wrapper-form-order {
    .el-form {
      width: 448px;
      padding: 30px 49px;
    }
  }
  .el-form {
    h1 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
    .description-order,
    .subtitle-order {
      font-size: 16px;
      line-height: 20px;
    }
    .table__order-start {
      .order__cell-info {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .tag__cell {
      .order__cell-name {
        font-size: 14px;
        line-height: 20px;
      }
      .tag__wrapper {
        display: none;
        visibility: hidden;
      }
      .tag__wrapper-mobile {
        display: block;
        visibility: visible;
      }
    }
    .cell__promo {
      .order__cell-info {
        .el-input.el-input--suffix {
          input {
            font-size: 14px;
            line-height: 20px;
            &::placeholder {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .el-button.btn__promo.el-button--primary.is-plain {
          font-size: 12px;
          line-height: 16px;
          padding: 14px 12px;
        }
      }
    }
    .user__data-title,
    .data__full-name {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }
    .container-user__data {
      margin-bottom: 16px;
      .wrapper-data__contact-info {
        p {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .el-form-item {
      .alert.alert--attention {
        padding: 16px 16px 16px 48px;
        p {
          font-size: 14px;
          line-height: 20px;
          &:before {
            left: -26px;
          }
          a {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      .inputCheckbox {
        .custom-checkbox + label {
          font-size: 14px;
          line-height: 20px;
          a {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
    .order__radio-buttons {
      margin-bottom: 24px;
      .el-radio-group {
        .el-radio__label {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper-form-order {
    .el-form {
      width: 428px;
    }
  }
  .el-form {
    .container-user__data {
      .wrapper-data__contact-info {
        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .el-form {
    width: 480px;
    padding: 24px;
    .description-order,
    .el-form .subtitle-order {
      font-size: 14px;
      line-height: 20px;
    }
    .tag__cell {
      align-items: baseline;
      flex-direction: column;
      padding: 12px 0;
      .order__cell-name {
        margin-bottom: 8px;
      }
    }
    .content-table__order {
      margin: 16px 0 24px 0;
      .tag__wrapper-mobile {
        max-width: 100%;
        width: 100%;
        .el-select.select-mobile__order-tag {
          width: 100%;
          .el-input {
            width: 100%;
            .el-input__inner {
              width: 100%;
            }
          }
        }
      }
    }
    .table__order-start {
      .order__cell-info {
        .cell__price {
          flex-direction: row-reverse;
          .discount {
            margin: 0 0 0 8px;
          }
        }
      }
    }
    .el-form-item {
      .el-button.btn.el-button--primary,
      .btn.btn__el-form-back {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 480px) {
  .wrapper-form-order {
    .el-form {
      width: 100%;
      padding: 24px;
    }
  }
  .el-form {
    .table__order-start {
      .order__cell-info {
        flex-direction: column;
        .el-input.el-input--suffix,
        .el-button.btn__promo.el-button--primary.is-plain {
          width: 100%;
        }
        .el-button.btn__promo.el-button--primary.is-plain {
          margin-top: 16px;
        }
      }
    }
    .container-user__data {
      .wrapper-data__contact-info {
        align-items: baseline;
        flex-direction: column;
        .contact__tel {
          margin-bottom: 16px;
        }
      }
    }
    .el-form-item {
      .inputCheckbox {
        .custom-checkbox + label {
          a {
            display: contents;
          }
        }
      }
    }
  }
}
</style>
