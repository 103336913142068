<template>
  <div class="event">
    <div class="event__info">
      <span class="event__title">{{ event.title }}</span>

      <div
        v-if="event.start || event.end"
        class="property__row"
      >
        <div
          v-if="event.start?.date"
          class="property"
        >
          <div class="property__title">{{ date1Title }}</div>
          <div class="property__value">{{ date1Value }}</div>
        </div>

        <div
          v-if="date2Value"
          class="property"
        >
          <div class="property__title">{{ date2Title }}</div>
          <div class="property__value">{{ date2Value }}</div>
        </div>
      </div>

      <div
        v-if="event.address"
        class="property__row"
      >
        <div class="property">
          <div class="property__title">{{ localeText.address }}</div>
          <div class="property__value">
            {{ event.address || '-' }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="detail"
      class="event__delimeter"
    />

    <div
      v-if="detail && event.description"
      class="event__info-additional"
    >
      <div class="property__row">
        <div class="property">
          <div class="property__title">
            {{ localeText.additional }}
          </div>
          <div class="property__value">
            {{ event.description || '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import localization from '@/i18n/localization';

export default {
  name: 'EventInfo',
  props: {
    event: {
      type: Object,
      required: true
    },
    detail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inProcess: false,
      localization: localization('dpo.events.info')
    };
  },
  computed: {
    localeText() {
      return {
        date: this.localization.widgetCaption('date'),
        dateStart: this.localization.widgetCaption('dateStart'),
        dateEnd: this.localization.widgetCaption('dateEnd'),
        duration: this.localization.widgetCaption('duration'),
        address: this.localization.widgetCaption('address'),
        additional: this.localization.widgetCaption('additional'),
      };
    },
    date1Title() {
      return this.event.start?.date === this.event.end?.date
        ? this.localeText.date
        : this.localeText.dateStart;
    },
    date2Title() {
      return this.event.start?.date === this.event.end?.date
        ? this.localeText.duration
        : this.localeText.dateEnd;
    },
    date1Value() {
      const date = this.event.start?.date_formatted;

      if (!date) {
        return '—';
      }

      return this.event.start?.date === this.event.end?.date
        ? date
        : date + ' ' + this.event.start?.time;
    },
    date2Value() {
      const start = this.event.start;
      const end = this.event.end;

      if (!end) {
        return '—';
      }

      if (start?.date === end?.date) {
        return start?.time === end?.time
          ? start?.time
          : start?.time + ' — ' + end?.time;
      }

      return end?.date_formatted + ' ' + end?.time;
    }
  }
};
</script>

<style scoped lang="scss">
.event {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  background-color: #fff;
  border: 0;

  &__title {
    display: block;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 24px;
  }

  &__info {
    background-color: #f8f9fb;
    padding: 32px;

    .property__title {
      font-size: 16px;
      line-height: 20px;
      color: #6b7a99;
    }

    .property__value {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
    }
  }

  &__info-additional {
    .property__title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }

    .property__value {
      font-size: 16px;
    }
  }

  .property__row {
    display: flex;
    margin-bottom: 24px;
    gap: 48px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .property {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
  }

  &__delimeter {
    border-top: solid 1px #edeff3;
  }
}

@media screen and (max-width: 500px) {
  .event {
    gap: 24px;

    .property__row {
      flex-direction: column;
      gap: 16px;
    }
  }
}
</style>
