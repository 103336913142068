<template>
  <div
    id="passport-block"
    class="passport-form-block"
  >
    <h2 class="h4">
      {{ title }}
    </h2>
    <el-alert
      v-if="item.errorText"
      class="alert alert--danger"
    >
      {{ item.errorText }}
    </el-alert>
    <form
      id="passport-form"
      class="passport-form common-wrapper__documents"
    >
      <div class="col-input-wrapper">
        <input-select
          id="type"
          label="Вид документа"
          placeholder="Вид документа"
          :options="type"
          :disabled="readonly"
          :value="$v.form.type"
        />
        <input-text
          id="serial"
          :label="getLabelReqIf('form', 'serial', 'Серия')"
          :disabled="readonly"
          placeholder=""
          :value="$v.form.serial"
        />
        <input-text
          id="number"
          label="Номер"
          :disabled="readonly"
          placeholder=""
          :value="$v.form.number"
        />
        <input-date-pass
          id="date_passport"
          label="Когда выдан"
          :disabled="readonly"
          placeholder="Когда выдан"
          :value="$v.form.date"
        />
      </div>
      <div class="col-input-wrapper">
        <input-issued-pass
          id="issued_by"
          label="Кем выдан"
          :disabled="readonly"
          :value="$v.form.issuedBy"
        />
        <input-mask
          id="division_code"
          :label="getLabelReqIf('form', 'divisionCode', 'Код подразделения')"
          placeholder=""
          :disabled="readonly"
          :mask="['### ###']"
          :value="$v.form.divisionCode"
        />
      </div>
      <div class="col-input-wrapper col-input-wrapper__two-col">
        <input-text
          id="birth_place"
          label="Место рождения"
          :disabled="readonly"
          :value="$v.form.birthPlace"
        />
        <input-select
          v-if="citizenshipAvailable"
          id="citizenship"
          class="citizenship-select"
          label="Гражданство"
          placeholder="Выберите из списка"
          :disabled="readonly"
          :options="countries()"
          :value="$v.form.citizenship_id"
        />
      </div>
      <div class="col-input-wrapper col-input-register-address">
        <input-data-address-v2
          id="register_address"
          label="Адрес регистрации"
          v-model="form.registerAddress"
          :validator="$v.form.registerAddress"
          :disabled="form.isSameRegAddress || readonly"
          @on-change="searchAddress"
          @on-select="setDadataItem"
        />
        <el-checkbox
          v-model="form.isSameRegAddress"
          :disabled="readonly"
          label="Совпадает с адресом регистрации в профиле"
          @change="handleChangeIsSameRegAddress"
        />
      </div>

      <div class="col-input-wrapper">
        <div class="input-wrap-files">
          <input-file
            id="passport"
            text-align="center"
            :multiple="true"
            :label="label.passport"
            :accept-extensions="arrImageExtensions"
            :disabled="readonly"
            :value="$v.form.files"
            @on-change="onChangeAttachment"
          />
        </div>
      </div>
      <div class="col-input-wrapper">
        <alert-attention :text="text" />
      </div>
      <div class="col-input-wrapper">
        <alert-attention :text="textInfo" />
      </div>
    </form>
  </div>
</template>

<script>
import {requiredIf} from 'vuelidate/lib/validators';

import InputDatePass from '@/components/profFormInputs/inputDatePass';
import InputIssuedPass from '@/components/profFormInputs/inputIssuedPass';
import InputFile from '@/components/inputComponent/InputFile';
import InputMask from '@/components/inputComponent/InputMask';
import InputSelect from '@/components/profFormInputs/inputSelect';
import InputText from '@/components/profFormInputs/inputText';
import getDadata from '@/components/widgets/elkOlympiads/getDadata.js';
import InputDataAddressV2 from '@/components/profFormInputs/inputDataAddressV2.vue';
import {mapGetters} from 'vuex';
import AlertAttention from '@/components/newAlerts/alertAttention.vue';
import {MAX_FILE_SIZE_TEXT} from '@/api';

const setData = item => ({
  id: item.id,
  type: item.type == '' ? item.type : +item.type,
  serial: item.serial,
  number: item.number,
  date: item.date,
  issuedBy: item.issuedBy,
  birthPlace: item.birthPlace,
  divisionCode: item.divisionCode,
  registerAddress: item.registerAddress,
  files: item.files,
  isSameRegAddress: item.isSameRegAddress || false,
  citizenship_id: item.citizenship_id
});

export default {
  name: 'PassportFormDocument',
  components: {
    AlertAttention,
    InputDataAddressV2,
    InputIssuedPass,
    InputDatePass,
    InputFile,
    InputMask,
    InputSelect,
    InputText
  },

  props: {
    item: {type: Object, required: true},
    rules: {type: Map, required: true},
    type: {type: Array, default: () => []},
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      title: 'Паспортные данные',
      label: {
        passport: 'Прикрепить копию паспорта'
      },
      arrImageExtensions: ['jpg', 'jpeg', 'pdf', 'png'],
      registerAddressSuggestions: [],
      birthPlaceSuggestions: [],
      form: this.item,
      prevRegAddress: null,
      text:
        'Вы можете прикрепить копию паспорта одним документом в одном из форматов: pdf, png, jpeg. Максимальный размер файла ' +
        MAX_FILE_SIZE_TEXT,
      textInfo:
        'Если хотите провести верификацию через предоставление оригиналов при личном посещении' +
        ' - не прикрепляйте скан-копию документа'
    };
  },

  computed: {
    ...mapGetters({
      countries: 'countries/get',
      fieldRules: 'fieldRules/getByDocumentTypeId'
    }),
    citizenshipAvailable() {
      const documentTypeId = this.form.type;

      if (documentTypeId) {
        return this.fieldRules(documentTypeId)?.some(
          rule => rule.code === 'citizenship' && rule.blocked === false
        );
      }

      return false;
    }
  },

  validations() {
    const form = {};
    this.rules.forEach((value, key) => {
      if (key == 'divisionCode') {
        form[key] = {
          required: requiredIf(function (nestedObj) {
            const typeItem = this.type.find(e => e.value === nestedObj.type);
            return typeItem?.slug === 'passport_rf';
          })
        };
      } else if (key === 'serial') {
        form[key] = {
          required: requiredIf(function (nestedObj) {
            const typeItem = this.type.find(e => e.value === nestedObj.type);
            return typeItem?.slug === 'passport_rf';
          })
        };
      } else {
        form[key] = {
          required: requiredIf(function () {
            return value;
          })
        };
      }
    });
    return {form};
  },

  watch: {
    item(val) {
      this.form = setData(val);
      this.$nextTick(() => {
        this.$v.$reset();
      });
    }
  },

  created() {
    this.emitValidation();
    this.$emit('on-select-register-address', null);
    this.prevRegAddress = this.form.registerAddress;
    this.$v.$reset();
  },
  methods: {
    getDadata,
    getLabelReqIf(parentForm, field, label) {
      let ieRequired = false;

      if (!this.$v) {
        return label;
      }

      if (
        this.$v[parentForm] &&
        this.$v[parentForm][field]?.$params?.required
      ) {
        ieRequired =
          this.$v[parentForm][field].$params.required.prop.call(
            this,
            this[parentForm]
          ) || false;
      }

      return label + (ieRequired ? '*' : '');
    },
    setDadataItem(address) {
      if (this.$v?.form?.registerAddress) {
        this.$v.form.registerAddress.$model = this.form?.registerAddress ?? '';
      }
      this.$emit('on-select-register-address', address);
    },
    async searchAddress(queryString, cb, id) {
      if (queryString) {
        try {
          const data = await this.getDadata('urlAddress', queryString);

          if (data) {
            switch (id) {
              case 'registerAddress':
                this.registerAddressSuggestions = data.suggestions;
                break;
              case 'birthPlace':
                this.birthPlaceSuggestions = data.suggestions;
                break;
              default:
                break;
            }
            cb(data.suggestions);
          } else {
            cb([]);
          }
        } catch (err) {
          cb([]);
          if (!err.isHandled) {
            this.setNotification({
              message: `Не удалось получить адреса. Попробуйте позже или обратитесь в службу поддержки`
            });
          }
        }
      } else {
        cb([]);
      }
    },
    onChangeAttachment(file) {
      //
    },
    emitValidation() {
      this.$emit('on-change', this.$v);
    },
    handleChangeIsSameRegAddress(checked) {
      if (checked) {
        this.form.registerAddress =
          this.$store.getters['profileData/getRegisterAddress']?.name;
      } else {
        this.form.registerAddress = this.prevRegAddress;
      }
    }
  }
};
</script>

<style lang="scss">
.passport-form {
  margin-top: 20px;
}
.el-select-dropdown.el-popper {
  .el-select-dropdown__item.selected {
    font-weight: 400;
  }
}

.passport-form.common-wrapper__documents {
  .col-input-wrapper:first-child {
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr 1fr;
    gap: 24px;
  }

  .col-input-wrapper:first-child,
  .col-input-wrapper:nth-child(3) {
    .wrapper-select__document {
      width: 100%;
      height: auto;
      border: unset;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;

      .span__select-document {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6b7a99;
        margin-bottom: 8px;
      }

      .el-select {
        width: 100%;
        height: 48px;

        .el-input {
          height: auto;

          .el-select__caret {
            color: $base-black-color;
            font-weight: bold;
          }

          .el-input__inner {
            height: 48px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $base-black-color;

            &::placeholder {
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: $base-black-color;
            }
          }
        }
      }

      &.error-system .el-select .el-input .el-input__inner {
        border: 1px solid $error-system-red;
      }
    }
  }

  .col-input-wrapper:nth-child(2) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;

    .input-form__prof {
      width: 100%;
    }
  }

  .col-input-wrapper:nth-child(3) {
    display: grid;
    grid-template-columns: 4.5fr 1fr;
    gap: 24px;
  }

  .input-form__prof,
  .el-input__inner {
    width: 100%;
    height: 48px;

    &::placeholder {
      color: $base-black-color;
    }
  }

  .inputWrap {
    margin-left: 0;
  }

  .input-wrap-document {
    .input-document__type {
      padding-right: 45px;
    }
  }
}

.col-input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .inputWrap {
    label:before {
      content: unset;
    }
  }

  .input-wrap-document {
    width: 100%;
    margin-bottom: 0;
  }

  .input-wrap-files {
    width: 100%;
    margin-bottom: 0;
  }
}

.passport-form-block {
  width: 100%;
}

@media (max-width: 1440px) {
  .passport-form.common-wrapper__documents {
    .col-input-wrapper:first-child {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(3, 1fr);

      .wrapper-select__document {
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        margin-bottom: 0;
      }

      .input-form__prof {
        width: 100%;
      }
    }

    .inputWrap:nth-child(4) {
      width: 100%;
    }

    .inputWrap {
      margin-left: 0;
      margin-bottom: 16px;
    }

    .col-input-wrapper:nth-child(2) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 16px;

      .input-form__prof {
        width: 100%;
      }

      .inputWrap {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 1199px) {
  .passport-form.common-wrapper__documents {
    .inputWrap:nth-child(4) {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .content.profile-page {
    h3 {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .passport-form.common-wrapper__documents {
    margin-bottom: 32px;

    .col-input-wrapper:nth-child(1) {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 16px;
      height: auto;
      width: 100%;

      .wrapper-select__document:nth-child(1) {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 2;
        grid-column-end: 5;
      }

      .inputWrap:nth-child(2) {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 3;
        margin-bottom: 0;
      }

      .inputWrap:nth-child(3) {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-row-end: 3;
        grid-column-end: 5;
        margin-bottom: 0;
      }

      .inputWrap:nth-child(4) {
        width: 100%;
        grid-row-start: 3;
        grid-column-start: 1;
        grid-row-end: 4;
        grid-column-end: 5;
        margin-bottom: 0;
      }

      .inputWrap {
        margin-bottom: 16px;
      }
    }

    .col-input-wrapper:nth-child(2) {
      grid-template-columns: 1fr;
      gap: 16px;
      margin-top: 16px;

      .inputWrap {
        margin-bottom: 16px;
      }
    }

    .col-input-wrapper:nth-child(3) {
      .inputWrap {
        margin-bottom: 0;
      }
    }

    .col-input-wrapper {
      .input-wrap-document {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 530px) {
  .passport-form.common-wrapper__documents {
    .col-input-wrapper {
      .input-wrap-document {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 530px) {
  .passport-form.common-wrapper__documents {
    .col-input-wrapper:first-child {
      .wrapper-select__document {
        .el-select {
          .el-input {
            .el-input__inner {
              font-size: 14px;
              line-height: 20px;

              &::placeholder {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    .input-wrap-document {
      .input-document__type {
        font-size: 14px;
        line-height: 20px;
      }

      .input-document__weight {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .col-input-wrapper:nth-child(2) {
      gap: 0;
    }
  }
}

@media (max-width: 480px) {
  .passport-form.common-wrapper__documents {
    padding: 16px;
  }
}

.col-input-register-address {
  flex-direction: column;
  align-items: flex-start !important;
  margin-bottom: 25px;

  > .inputWrap {
    margin-bottom: 3px;
  }
}
</style>
