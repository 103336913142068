<template>
  <div
    class="table__row"
    :data-id="event.id"
  >
    <div class="table__cell">
      {{ event.title }}
    </div>

    <div
      class="table__cell"
      :class="{table__cell__hidden: !showColTime}"
    >
      <div class="table__cell-header">
        {{ localeText.time }}
      </div>
      {{ event.start?.time || '—' }}

      <template v-if="event.end?.time"> — {{ event.end?.time }} </template>
    </div>

    <div class="table__cell">
      <div class="table__cell-header">
        {{ localeText.address }}
      </div>
      {{ event.address || '—' }}
    </div>

    <div class="table__cell">
      <div class="cell__btns">
        <el-tooltip
          :content="localeText.alreadyRegistered"
          placement="bottom"
          effect="light"
          popper-class="el-tooltip__custom"
          :disabled="event.can_order"
        >
          <el-button
            v-if="event.actual"
            class="btn"
            @click="handleBtnClick"
          >
            {{ localeText.cancel }}
          </el-button>
        </el-tooltip>
      </div>
    </div>

    <el-dialog
      class="hse-modal hse-modal__standart"
      :title="localeText.modalTitle"
      :visible.sync="showConfirmModal"
      @closed="handleCancel"
    >
      <event-order-cancel-confirmation
        :event="event"
        @onConfirm="handleConfirm"
        @onCancel="handleCancel"
      />
    </el-dialog>
  </div>
</template>

<script>
import localization from '@/i18n/localization';
import EventOrderCancelConfirmation from '@/components/dpoEvent/EventOrderCancelConfirmation.vue';

export default {
  name: 'ScheduleTodayFreeEvent',
  components: {EventOrderCancelConfirmation},
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showConfirmModal: false,
      localization: localization('dpo.events.info')
    };
  },
  computed: {
    localeText() {
      return {
        time: this.localization.widgetCaption('time'),
        address: this.localization.widgetCaption('address'),
        cancel: this.localization.widgetCaption('btnCancelOrder'),
        modalTitle: this.localization.widgetCaption('orderDeclineTitle')
      };
    },
    showColTime() {
      const {start, end} = this.event;

      return start?.date && end?.date && start.date === end.date;
    }
  },
  methods: {
    handleBtnClick() {
      if (this.event.actual) {
        this.showConfirmModal = true;
      }
    },
    handleConfirm(event) {
      this.$emit('onEventCanceled', event);
      this.showConfirmModal = false;
    },
    handleCancel() {
      this.showConfirmModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.table {
  &__row {
    display: table-row;
    width: 100%;
  }

  &__cell {
    display: table-cell;
    position: relative;
    padding: 16px 24px;
    box-sizing: border-box;
    border-bottom: 1px solid $color-gray-morn-3;
    font-size: 16px;
    line-height: 20px;
    color: #0f0f14;
    vertical-align: middle;

    &__hidden {
      visibility: hidden;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: calc(100% - 32px);
      border-right: 1px solid $color-gray-morn-3;
      left: 0;
      top: 16px;
    }

    &:first-of-type {
      font-size: 18px;
      font-weight: 600;

      &:before {
        display: none;
      }
    }

    &-header {
      font-size: 14px;
      line-height: 20px;
      color: $color-gray-dusk;
      margin-bottom: 6px;
    }

    &:nth-child(1) {
      width: calc(50% - 180px);
    }

    &:nth-child(2) {
      width: 180px;
    }

    &:nth-child(3) {
      width: calc(50% - 200px);
    }

    &:nth-child(4) {
      width: 200px;

      &:before {
        display: none;
      }
    }
  }

  &__row:last-of-type {
    .table__cell {
      border-bottom: 0;
    }
  }
}

@media screen and (max-width: 1199px) {
  .table {
    &__cell {
      padding: 8px 12px;

      &:nth-child(2) {
        width: 100px;
      }

      &:nth-child(3) {
        width: auto;
      }

      &:nth-child(4) {
        max-width: 200px;
      }

      &:before {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .table {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    background-color: unset;
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;

    &__row {
      display: grid;
      grid-template-columns: 160px repeat(3, auto);
      grid-template-rows: repeat(3, auto);
      grid-template-areas:
        'header . . .'
        'time . . .'
        'address . . button';
      row-gap: 5px;
      position: relative;
      background-color: $color-background-white;
      border-radius: 4px;
      padding: 8px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        border-bottom: 1px solid $color-gray-morn-3;
        left: 0;
        top: 0;
      }
    }

    &__cell {
      display: block;
      border: none;
      width: 100% !important;

      &:before {
        display: none;
      }

      &:nth-child(1) {
        grid-area: header;
        grid-column: span 4;
      }

      &:nth-child(2) {
        grid-area: time;
      }

      &:nth-child(3) {
        grid-area: address;
        grid-column: span 3;
      }

      &:nth-child(4) {
        grid-area: button;
        align-self: end;
        justify-self: end;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .table {
    &__row {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, auto);
      grid-template-areas:
        'header'
        'time'
        'address'
        'button';
      row-gap: 8px;
      padding: 16px 0 8px 0;

      &:first-of-type {
        &:before {
          display: none;
        }
      }
    }

    &__cell {
      padding-left: 0;
      padding-right: 0;

      &:nth-child(1) {
        line-height: 24px;
      }

      &:nth-child(4) {
        max-width: none;
      }
    }
  }
}
</style>
