<template>
  <div class="shedule-today">
    <h3 class="shedule-today__date">
      {{ formRuDate(currentDay.date) }}
    </h3>

    <template v-if="currentDay.events?.length">
      <shedule-today-event
        v-for="classes in currentDay.events"
        :id="classes.id"
        :key="classes.id"
        :event-begin="classes.eventBegin"
        :event-end="classes.eventEnd"
        :type="classes.type"
        :type-of-event="classes.typeOfEvent"
        :caption="classes.caption"
        :direction="classes.direction"
        :venue="classes.venue"
        :venue-link="classes.venueLink"
        :auditorium="classes.auditorium"
        :auditorium-link="classes.auditoriumLink"
        :teacher-rang="classes.teacherRang"
        :teacher-name="classes.teacherName"
      />
    </template>

    <template v-if="currentDay.freeEvents?.length">
      <div class="table">
        <schedule-today-free-event
          v-for="event in currentDay.freeEvents"
          :key="event.id"
          :event="event"
          @onEventCanceled="onEventCanceled"
        />
      </div>
    </template>
  </div>
</template>
<script>
import ScheduleTodayEvent from '@/components/schedule/ScheduleTodayEvent';
import ScheduleTodayFreeEvent from '@/components/schedule/ScheduleTodayFreeEvent';

export default {
  name: 'ScheduleToday',
  components: {
    ScheduleTodayFreeEvent,
    'shedule-today-event': ScheduleTodayEvent
  },
  props: {
    currentDaySchedule: Object
  },
  data() {
    return {
      currentDay: {}
    };
  },
  mounted() {
    this.currentDay = this.currentDaySchedule;
  },
  methods: {
    formRuDate(strDate) {
      let date = new Date(strDate);
      let year = date.getFullYear();
      let month = Number(date.getMonth());
      let numberDay = Number(date.getDate());
      let dayOfTheWeek = Number(date.getDay());
      let arDays = [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота'
      ];
      let arMonths = [
        'Января',
        'Февраля',
        'Марта',
        'Апреля',
        'Мая',
        'Июня',
        'Июля',
        'Августа',
        'Сентября',
        'Октября',
        'Ноября',
        'Декабря'
      ];

      return `${arDays[dayOfTheWeek]}, ${numberDay} ${arMonths[month]}`;
    },
    onEventCanceled(event) {
      this.currentDay.freeEvents = this.currentDay.freeEvents.filter(
        ({id}) => event.id !== id
      );

      this.$router.push({
        name: 'dpo.events.order_status',
        query: {
          order: event.order_hash
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.shedule-today {
  padding: 26px 24px;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
  border-radius: 4px;
  box-sizing: border-box;

  .shedule-today__date {
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 1.33;
    font-weight: 600;
  }
}

.table {
  display: table;
  width: 100%;
}

@media screen and (max-width: 870px) {
  .shedule-today {
    .shedule-today__date {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
}
</style>
