const RESET_PASSWORD_ROUTE = '/password_recovery';
const REGISTRATION_ROUTE = '/registration';
const FEEDBACK_ROUTE = '/feedback';
const SERVICE_ROUTE = '/service';
const SERVICE_DETAIL_ROUTE = '/service-detail';
const SERVICE_IT_DETAIL_ROUTE = '/service-it-detail';
const HOME_ROUTE = '/';
const ERROR_ROUTE = '/error';
const STYLEGUIDE_ROUTE = '/styleguide';
const TASK_ROUTE = '/task';
const CLAIM_ROUTE = '/claim';
const PROFILE_ROUTE = '/profile';
/** fix: страница приходит со старого бэка и перехватывает роутер, надо исправить потом обраттно на /profile */
const HRDETAIL_ROUTE = '/hrdetail';
const HR_ROUTE = '/hr';
const NEWS_ROUTE = '/news';
const FAQ_ROUTE = '/faq';
const SCHEDULE_ROUTE = '/schedule';
const STAT_ROUTE = '/stat';
const USER_SUGGETSTION_ROUTE = '/user-suggestions';
const LOGOUT = '/logout';
const NOT_FOUND = '/404';
const AUTHORIZATION = '/dev/authorization';
const RECOVERYPAGE = '/dev/recoveryPage';
const REGISTRATIONPAGE = '/dev/registrationPage';
const MAINPAGE = '/dev/main-page';
const SELECTED_PROGRAMS = '/dpo/favorites';
const RECOMMENDED_PROGRAMS = '/dpo/recommended-programs';
const PROGRAM_VIEWING_HISTORY = '/dpo/browsing-history';
const PROFILEPAGE = '/profile';
/** fix: страница приходит со старого бэка и перехватывает роутер, надо исправить потом в 'src/store/index.js' метод setNewRoutes */
const DOCUMENTSPAGE = '/dpo/documentsPage';
const PROGRAMSPAGE = '/dpo/my-programs';
const SERVICES = '/services';
const QUESTIONS_AND_ANSWERS = '/faq';
const QUESTIONS_AND_ANSWERS_DETAILED = '/faq';
const NEWBASKET = '/basket';
const ORDER = '/order';
const NOTIFICATIONS = '/dpo/notifications';
const REVIEWPAGE = '/dpo/review';
const REVIEWFINISH = '/dpo/review_finish';
const MYSCHEDULE = '/dpo/my-schedule';
const REFERENCEPAGE = '/dpo/get-help';
const COMPARISONPAGE = '/dpo/comparison';
const COMPARISON = '/dev/comparison';
const EVENTS = '/dev/events';
const CONGRATULATIONONPAYMENT = '/congratulation-on-payment';
const IT_SUPPORT =
  'https://pmo.hse.ru/servicedesk/customer/portal/81/create/1815';
const IT_SUPPORT_DPO =
  'https://pmo.hse.ru/servicedesk/customer/portal/81/group/317';
const IT_SUPPORT_REPRESENT =
  'https://pmo.hse.ru/servicedesk/customer/portal/81/create/2706';
const DPO_CORP_REG = '/dpo/registrationcorppage';
const DPO_CORP_SERVICES = '/dpo/corp/services';
const ELK_PROFILE_MAIN_ALT = '/elk-profile-main-alt';
const SERVER_ERROR = '/server-error';
const GRANTS_ROUTE = '/grants';
const PAYSLIP = '/pay-slip';

// Пример
//const DPO_CORP_PAGE = 'dpo/corp/services';

export {
  USER_SUGGETSTION_ROUTE,
  SCHEDULE_ROUTE,
  FAQ_ROUTE,
  NEWS_ROUTE,
  HR_ROUTE,
  HRDETAIL_ROUTE,
  CLAIM_ROUTE,
  TASK_ROUTE,
  STYLEGUIDE_ROUTE,
  ERROR_ROUTE,
  RESET_PASSWORD_ROUTE,
  REGISTRATION_ROUTE,
  FEEDBACK_ROUTE,
  HOME_ROUTE,
  SERVICE_ROUTE,
  PROFILE_ROUTE,
  STAT_ROUTE,
  AUTHORIZATION,
  RECOVERYPAGE,
  REGISTRATIONPAGE,
  MAINPAGE,
  SELECTED_PROGRAMS,
  RECOMMENDED_PROGRAMS,
  PROGRAM_VIEWING_HISTORY,
  PROFILEPAGE,
  DOCUMENTSPAGE,
  PROGRAMSPAGE,
  SERVICES,
  QUESTIONS_AND_ANSWERS,
  QUESTIONS_AND_ANSWERS_DETAILED,
  NEWBASKET,
  ORDER,
  NOTIFICATIONS,
  REVIEWPAGE,
  REVIEWFINISH,
  MYSCHEDULE,
  REFERENCEPAGE,
  COMPARISONPAGE,
  COMPARISON,
  EVENTS,
  CONGRATULATIONONPAYMENT,
  SERVICE_DETAIL_ROUTE,
  LOGOUT,
  SERVICE_IT_DETAIL_ROUTE,
  NOT_FOUND,
  IT_SUPPORT,
  IT_SUPPORT_DPO,
  DPO_CORP_REG,
  DPO_CORP_SERVICES,
  ELK_PROFILE_MAIN_ALT,
  IT_SUPPORT_REPRESENT,
  SERVER_ERROR,
  GRANTS_ROUTE,
  PAYSLIP
};
