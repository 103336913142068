<template>
  <div class="modal">
    <span class="modal__text">
      {{ localeText.text }}
    </span>

    <event-info :event="event" />

    <div class="modal__buttons">
      <el-button
        class="btn btn--gray"
        :disabled="loading"
        @click="onCancel"
      >
        {{ localeText.btnCancel }}
      </el-button>

      <el-button
        class="btn"
        :loading="loading"
        @click="onConfirm"
      >
        {{ localeText.btnConfirm }}
      </el-button>
    </div>
  </div>
</template>

<script>
import localization from '@/i18n/localization';
import {mapActions, mapGetters} from 'vuex';
import EventInfo from '@/components/dpoEvent/EventInfo.vue';
import Axios from '@/utils/Elk-Axios';
import {EVENT_ORDERS} from '@/api';

export default {
  name: 'EventOrderCancelConfirmation',
  components: {EventInfo},
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localization: localization('dpo.events.info'),
      loading: false
    };
  },
  computed: {
    ...mapGetters(['getLinkUrlBySignature']),
    localeText() {
      return {
        text: this.localization.widgetCaption('orderDeclineText'),
        btnConfirm: this.localization.widgetCaption('btnCancelOrderConfirm'),
        btnCancel: this.localization.commonCaption('cancel')
      };
    }
  },
  methods: {
    ...mapActions({
      refreshSchedule: 'schedule/refreshData'
    }),
    onConfirm() {
      this.loading = true;

      Axios.post(EVENT_ORDERS + `/${this.event.order_hash}/cancel`)
        .then(() => {
          this.refreshSchedule();
          this.$emit('onConfirm', this.event);
        })
        .finally(() => (this.loading = false));
    },
    onCancel() {
      this.$emit('onCancel');
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__text {
    font-size: 16px;
    line-height: 20px;
    border-bottom: 0;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  .event {
    padding: 0;
    border-bottom: 0;
  }
}
</style>
